import React from 'react';
import ImageZoom from 'react-medium-image-zoom';
import * as style from '../article/imageCaption.module.scss';

const shouldZoom = () => {
  return window.innerWidth >= 980;
};

const ZoomableImage = props => (
  <ImageZoom
    image={{
      src: props.src,
      alt: props.altText,
      className: style.image,
    }}
    zoomImage={{
      src: props.original,
      alt: props.altText,
    }}
    defaultStyles={{
      overlay: {
        background: 'rgba(0, 0, 0, 0.85)',
      },
    }}
    shouldHandleZoom={shouldZoom}
  />
);

export default ZoomableImage;
