import React, { Component } from 'react';
import ZoomableImage from './zoomableImage';
import PropTypes from 'prop-types';
import * as style from './lazyImage.module.scss';

class LazyImage extends Component {
  constructor() {
    super();
    this.state = {
      startedLoad: false,
      reveal: false,
      loadComplete: false,
    };
  }
  componentDidMount() {
    if (!!this.image) {
      this.image.addEventListener('load', this.handleLoad);
    }
    if (!this.props.hasRevealer || this.props.revealed) {
      this.load();
    }
  }
  componentWillUnmount() {
    if (!!this.image) {
      this.image.removeEventListener('load', this.handleLoad);
    }
    clearTimeout(this.revealTimeout);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.startedLoad && !this.props.revealed && nextProps.revealed) {
      this.load();
    }
  }
  load() {
    this.setState({ startedLoad: true });
  }

  handleLoad = () => {
    this.setState({
      loadComplete: true,
      reveal: true,
    });
  };

  getStyle() {
    const display = this.state.reveal && !this.props.hidden;

    if (this.props.fitSize) {
      const width =
        typeof this.props.width === 'string'
          ? parseInt(this.props.width, 10)
          : this.props.width;
      const height =
        typeof this.props.height === 'string'
          ? parseInt(this.props.height, 10)
          : this.props.height;
      return {
        paddingBottom: `${height / width * 100}%`,
        opacity: display ? this.props.opacity : 0,
      };
    } else {
      return {
        opacity: display ? this.props.opacity : 0,
      };
    }
  }

  renderImage() {
    const isZoom = this.props.zoomable && this.props.original;

    if (this.state.loadComplete && isZoom) {
      return (
        <ZoomableImage
          src={this.props.src}
          altText={this.props.alt}
          original={this.props.original}
        />
      );
    } else {
      return (
        <img
          src={this.state.startedLoad ? this.props.src : ''}
          alt={this.props.alt ? this.props.alt : ''}
          ref={el => {
            this.image = el;
          }}
        />
      );
    }
  }

  render() {
    return (
      <div
        className={`${style.lazyImage} ${
          this.props.fitSize ? style.fitSize : ''
        }`}
        style={this.getStyle()}
      >
        {this.renderImage()}
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<img
          class="${style.lazyImage}"
          style="opacity: ${this.props.opacity}"
          src="${this.props.src}"
          alt="${this.props.alt ? this.props.alt : ''}"
        />`,
          }}
        />
      </div>
    );
  }
}

LazyImage.defaultProps = {
  opacity: 1,
  hidden: false,
  fitSize: false,
};

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  opacity: PropTypes.number,
  hidden: PropTypes.bool,
  fitSize: PropTypes.bool,
};

export default LazyImage;
